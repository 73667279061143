/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Dropdown, Form, FormGroup, Modal, Row, Table } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { StatCard } from '../../components/Card';
import { CarrierFilter, CarrierFilterFromDeliveryService, DateFilter, DeliveryServiceFilter, GreenPhoxFilter, ListPageDriverFilter, ShipperFilter, ShipperGroupFilter, StatusFilter } from '../../components/EntityFilter';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { StatusBadge } from '../../components/StatusBadge';
import { TableExportFilter, TableKeywordSearch, TablePagination, TimeZoneFilter } from '../../components/TableFilter';
import BoxStatus from "../../data/box-status.json";
import { DELAY_TIMEOUT, getTZ, PAGE_SIZE, toLocalDate, toLocalTime } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4"
import { TableClearFilter } from '../../components/TableFilter';


const exportShipmentData = /* GraphQL */ `
      mutation ExportShipmentData($input: AWSJSON!) {
        exportShipmentData(input: $input)
      }
    `;

const updateShipmentBoxMutation = /* GraphQL */ `
	mutation UpdateShipmentBox(
	  $input: UpdateShipmentBoxInput!
	) {
	  updateShipmentBox(input: $input) {
		id
	  }
	}
  `;

const createBoxStatusHistory = /* GraphQL */ `
  mutation CreateBoxStatusHistory(
	$input: CreateBoxStatusHistoryInput!
	$condition: ModelBoxStatusHistoryConditionInput
  ) {
	createBoxStatusHistory(input: $input, condition: $condition) {
	  id
	  boxId
	  userId
	  shipmentId
	}
  }
  `;


const ShipmentList = () => {

	const myUser = useSelector(state => state.slice.USER)
	const navigate = useNavigate()

	const [spinner, showSpinner] = useState(false);
	const [shipments, setShipments] = useState([])
	const [facets, setFacets] = useState({})
	const [tz, setTz] = useState("EST");
	const [keyword, setKeyword] = useState('')
	const [shipperGroup, setShipperGroup] = useState()
	const [shipper, setShipper] = useState()
	const [carrier, setCarrier] = useState()
	const [status, setStatus] = useState();
	const [deliveryServices, setDeliveryServices] = useState();
	const [deliveryService, setDeliveryService] = useState();
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [isGreenPhox, setIsGreenPhox] = useState();
	const [boxModal, showBoxModal] = useState(false);
	const [boxModalShipment, setBoxModalShipment] = useState({});
	const [boxStatusHistoryData, setBoxStatusHistoryData] = useState({});
	const [statusDate, setStatusDate] = useState(new Date());
	const [driver, setDriver] = useState();
	const [resetDate, setRestDate] = useState(0);
	const [timeZone, setTimeZone] = useState("America/New_York");

	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz(timeZone)?.startOf('day').unix(),
		toDate: moment().tz(timeZone)?.endOf('day').unix()
	});
	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/shipment",
		})
	}, [])

	useEffect(() => {
		const getTimeZone = async () => {
			const timez = await getTZ(shipperGroup?.value, shipper?.value, carrier?.value);
			if (timez) {
				setTimeZone(timez?.id);
				setTz(timez?.alias)
			}
			else {
				setTimeZone("America/New_York")
				setTz("EST")
			}
		};

		if (shipperGroup?.value || shipper?.value || carrier?.value) getTimeZone();
	}, [shipperGroup?.value, shipper?.value, carrier?.value]);
	useEffect(() => {
		setPageNumber(0);
		getElasticShipment();
	}, [shipperGroup, shipper, carrier, status, deliveryServices, isGreenPhox?.value, deliveryService, driver]);

	useEffect(() => {
		const delay = setTimeout(() => { setPageNumber(0); getElasticShipment(); }, DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword, dateFilters])

	useEffect(() => {
		getElasticShipment();
	}, [pageNumber]);

	useEffect(() => {
		setDriver(null)
		if (!shipperGroup || !shipper)
			setTimeZone("America/New_York");
		setTz("EST");
	}, [carrier, shipper, shipperGroup])

	const getElasticShipment = async () => {
		showSpinner(true);

		try {
			const apiName = 'api';
			const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					aggs: {
						status: {
							terms: {
								"field": "status.keyword",
								"size": 100
							}
						}
					},
					sort: [
						{
							"_score": { "order": "desc" }
						},
						{
							"createdTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					query: {
						bool: {
							must: [],
							must_not: []
						}
					}
				}
			};

			if (!keyword) {
				init.body.query.bool.must.push({
					range: {
						"expectedDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId",
					"extTrackingNumber",
					"shipTo.address.address1",
					"shipTo.address.postalCode",
					"items.number.keyword"
				]
				if (+keyword) {
					fields.push("number")
				}
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (shipper) init.body.query.bool.must.push({ match: { shipperId: shipper?.value } })
			if (shipperGroup) init.body.query.bool.must.push({ match: { shipperGroupId: shipperGroup?.value } })
			if (carrier) init.body.query.bool.must.push({ match: { carrierId: carrier?.value } })
			if (driver) {
				if (driver?.value === "driverNotAssigned") {
					init.body.query.bool.must_not.push({ exists: { field: "driver.name" } })
				} else { init.body.query.bool.must.push({ match: { driverId: driver?.value } }) }
			}
			if (deliveryService) init.body.query.bool.must.push({ match: { deliveryServiceId: deliveryService?.value } })
			if (status) init.body.query.bool.must.push({ match: { status: status?.value } });

			if (isGreenPhox?.value === false) init.body.query.bool.must.push({ match: { isGreenPhox: isGreenPhox?.value } })
			else if (isGreenPhox?.value === true) init.body.query.bool.must.push({ match: { isGreenPhox: isGreenPhox?.value } })

			const data = await API.post(apiName, path, init);

			let statusCount = {};
			data.aggregations.status.buckets?.forEach((item) => {
				statusCount[item.key] = item.doc_count
			})

			setFacets(statusCount)
			setShipments(data?.hits?.hits);
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			showSpinner(false);

		} catch (error) {
			console.error('ealstic error', error);
			showSpinner(false);

		}
	}

	const downloadReport = async () => {
		let downloadingToastId;
		try {
			downloadingToastId = toast.loading("Downloading...");
			let query = {
				query: exportShipmentData,
				variables: {
					input: JSON.stringify({
						expectedDeliveryTime: {
							fromDate: dateFilters?.fromDate ?? null,
							toDate: dateFilters?.toDate ?? null
						},
						shipperGroupId: shipperGroup?.value || null,
						shipperId: shipper?.value ?? null,
						carrierId: carrier?.value ?? null,
						status: status?.value || null,
						deliveryServiceId: deliveryService?.value || null,
						driverId: driver?.value ?? null,
						timezone: timeZone
					})
				}
			};
			const data = await API.graphql(query);
			const link = JSON.parse(data.data.exportShipmentData).body;
			if (link) {
				const linkTag = document.createElement('a');
				linkTag.href = link;
				linkTag.click();
				linkTag.remove();
				toast.remove(downloadingToastId);
				toast.success("Download finished");
			} else return toast.error("No data found !")

		} catch (error) {
			console.error(error);
			toast.error("Something went wrong while exporting shipments.");
		} finally { toast.dismiss(downloadingToastId) }
	};


	const UpdateBoxStatus = () => {

		const inputDate = moment(statusDate).format('YYYY-MM-DD HH:mm')
		const tzDateTime = moment.tz(inputDate, boxModalShipment?.shipFrom?.timezone?.id).unix()

		if (!boxStatusHistoryData?.status) {
			toast.error('Box status is Required')
		} else {
			showSpinner(true)
			let boxInput = {
				id: boxModalShipment?.boxId,
				status: boxStatusHistoryData?.status,
			}

			let statusArray = ['RETURNED', 'AVAILABLE']
			if (statusArray?.includes(boxStatusHistoryData?.status)) {
				boxInput.userId = null;
				boxInput.carrierId = null;
				boxInput.shipmentId = null;
			}

			let historyInput = {
				boxId: boxModalShipment?.boxId,
				shipmentId: boxModalShipment?.id,
				userId: myUser?.id,
				status: boxStatusHistoryData?.status,
				createdTime: tzDateTime,
				description: boxStatusHistoryData?.description
			}

			showSpinner(false);
			let task = []
			task.push(API.graphql({ query: updateShipmentBoxMutation, variables: { input: boxInput } }))

			let statusArray2 = ['IN_USE', 'OUT_FOR_RETURN', 'RETURNED'];
			statusArray2?.includes(boxStatusHistoryData?.status) && task.push(API.graphql({ query: createBoxStatusHistory, variables: { input: historyInput } }))

			Promise.all(task).then((response) => {
				toast.success('Box status has been updated')
				setBoxStatusHistoryData()
				// getShipments()
			}).catch((error) => {
				toast.error('Opps,something went wrong');
				console.error(error);
			}).finally(() => { showSpinner(false); showBoxModal(false) })
		}

	}
	const clearFilters = () => {
		setDateFilters({
			fromDate: moment().tz('America/New_York')?.startOf('day').unix(),
			toDate: moment().tz('America/New_York')?.endOf('day').unix()
		});
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
		setShipperGroup(null);
		setShipper(null);
		setCarrier(null);
		setDriver(null);
		setDeliveryService(null);
		setStatus(null);
		setIsGreenPhox(null);
		setTimeZone("America/New_York");
		setTz("EST");
	}
	const tzHandle = (e) => {
		setTimeZone(e);
	};

	return (
		<>
			<PageHeader name='Shipments' >
				<Row>
					<ShipperGroupFilter value={shipperGroup} onChange={setShipperGroup} />
					<ShipperFilter value={shipper} onChange={setShipper} shipperGroup={shipperGroup} />
					{shipper?.value && <CarrierFilterFromDeliveryService value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} />}
					{!shipper?.value && < CarrierFilter value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} />}
					{carrier?.value && <ListPageDriverFilter value={driver} onChange={setDriver} carrier={carrier} shipperGroup={shipperGroup} shipper={shipper} />}
					{(shipper?.value && carrier?.value) && <DeliveryServiceFilter value={deliveryService} myShipper={shipper} carrier={carrier} onChange={setDeliveryService} />}
					<StatusFilter value={status} onChange={setStatus} />
					<GreenPhoxFilter value={isGreenPhox} onChange={setIsGreenPhox} />
					<DateFilter key={resetDate} onChange={setDateFilters} />
					<TimeZoneFilter
						title={"Time Zone"}
						setTimeZone={setTimeZone}
						dark={true}
						onChange={tzHandle}
						tz={tz}
						setTz={setTz}
					/>
					<TableClearFilter onClick={clearFilters} styleName={"mt-4"} />
				</Row>
			</PageHeader>

			<Container fluid>
				<Row>
					<StatCard title='Ready for Pickup / Open' value={(facets?.READY_FOR_PICKUP || 0) + (facets?.OPEN || 0)} icon='box' />
					<StatCard title='Out for Delivery' value={(facets?.OUT_FOR_DELIVERY || 0) + (facets?.IN_TRANSIT || 0)} icon='truck' />
					<StatCard title='Delivered' value={facets?.DELIVERED || 0} icon='check-circle' />
					<StatCard title='Exceptions / Returns' value={(facets?.EXCEPTION || 0) + (facets?.OUT_FOR_RETURN || 0) + (facets?.RETURNED || 0)} icon='alert-triangle' />
				</Row>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
							<TableExportFilter onChange={downloadReport} option="Download as Excel" />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm' className='mb-0' >
							<thead>
								<tr>
									<th className='text-center'>Shipment #</th>
									<th>Shipper</th>
									<th>Recipient</th>
									<th className='text-center'>Delivery Service</th>
									<th>Driver / Courier</th>
									<th className='text-center'>Entered At</th>
									<th className='text-center'>Due By</th>
									<th className='text-center'>Delivered At</th>
									<th className='text-center'>Status</th>
									{isGreenPhox && <th className='text-center'> Box Status </th>}
								</tr>
							</thead>
							<tbody>
								{
									shipments?.length > 0 && shipments?.map((shipment) => {
										return (
											<tr key={shipment._source.id} style={{ cursor: 'pointer' }}
												onClick={(e) => {
													if (e.metaKey || e.ctrlKey) {
														window.open(`/shipment/${shipment._source?.id}`, '_blank');
													} else {
														window.open(`/shipment/${shipment._source?.id}`, '_self');
													}
												}}>
												<td className='text-center'>
													<div>
														{shipment?._source.isGreenPhox ? <img src="img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'20px'} width={'20px'} /> : <small className="ms-5"> </small>}
														{shipment._source.number}
													</div>
													{shipment._source.batch_number > 0 && (<div className='small text-muted'>Batch #{shipment?._source.batch_number}</div>)}
												</td>
												<td>
													{shipment._source.shipFrom?.name}
													<div className='small text-muted'>{shipment._source.shipFrom?.address?.city}, {shipment._source.shipFrom?.address?.state}</div>
												</td>
												<td>
													{shipment._source.shipTo?.name}
													<div className='small text-muted'>{shipment._source.shipTo?.address?.city}, {shipment._source.shipTo?.address?.state}</div>
												</td>
												<td className='text-center'>
													{shipment._source.deliveryService?.name}
												</td>
												<td>
													<div>{shipment._source.driver?.name || 'Unassigned'}</div>
													<div className='text-small text-muted'>{shipment._source.carrier?.alias}</div>
												</td>
												<td className='text-center'>
													{toLocalTime(shipment?._source.createdTime, shipment?._source.shipFrom?.timezone?.id)}
													<div className='small text-muted'>{toLocalDate(shipment?._source.createdTime, shipment?._source.shipFrom?.timezone?.id)}</div>
												</td>
												<td className='text-center'>
													{
														shipment._source.expectedDeliveryTime && <>
															{toLocalTime(shipment?._source.expectedDeliveryTime, shipment?._source.shipFrom?.timezone?.id)}
															<div className='small text-muted'>{toLocalDate(shipment?._source.expectedDeliveryTime, shipment?._source.shipFrom?.timezone?.id)}</div>
														</>
													}
												</td>
												<td className='text-center'>
													{
														shipment._source.actualDeliveryTime ? <>
															{toLocalTime(shipment?._source.actualDeliveryTime, shipment?._source.shipFrom?.timezone?.id)}
															<div className='small text-muted'>{toLocalDate(shipment?._source.actualDeliveryTime, shipment?._source.shipFrom?.timezone?.id)}</div>
														</> : '-'
													}
												</td>
												<td className='text-center'>
													<StatusBadge status={shipment._source.status} />
												</td>
												{isGreenPhox && <td className='text-center' onClick={(e) => e.stopPropagation()} >
													<Dropdown>
														<Dropdown.Toggle variant='light' size='sm' >{BoxStatus[shipment?._source.box?.status]}</Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item onClick={() => { showBoxModal(true); setBoxModalShipment(shipment) }}> Update Status </Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</td>}
											</tr>
										)
									})
								}
							</tbody>

							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={8} />

							<Modal Modal show={boxModal} onHide={() => showBoxModal(false)}>
								<Modal.Header>
									<h4>Update Box Status</h4>
								</Modal.Header>
								<Modal.Body>
									<FormGroup  >
										<FormLabel> Box Status</FormLabel>

										<Form.Select value={boxStatusHistoryData?.status} onChange={(e) => setBoxStatusHistoryData({ ...boxStatusHistoryData, status: e.target.value })}>
											<option value={''}>Select status</option>
											{Object.keys(BoxStatus).map((item, index) => <option key={index} value={item}>{BoxStatus[item]}</option>)}
										</Form.Select>
									</FormGroup>
									<FormGroup className='my-3' >
										<FormLabel> Description</FormLabel>
										<textarea className='form-control' rows={2} onChange={(e) => setBoxStatusHistoryData({ ...boxStatusHistoryData, description: e.target.value })} />
									</FormGroup>

									{
										['IN_USE', 'OUT_FOR_RETURN', 'RETURNED'].includes(boxStatusHistoryData?.status) && <>
											<FormLabel required>
												{boxStatusHistoryData?.status === 'IN_USE' && 'Actual In Use Time'}
												{boxStatusHistoryData?.status === 'OUT_FOR_DELIVERY' && 'Actual Pickup Time'}
												{boxStatusHistoryData?.status === 'DELIVERED' && 'Actual Delivery Time'}
												{boxStatusHistoryData?.status === 'OUT_FOR_RETURN' && 'Out for Return Time'}
												{boxStatusHistoryData?.status === 'RETURNED' && 'Returned Time'}
											</FormLabel>
											<div className='mb-3'>
												<Flatpickr className='form-control d-block' name='statusDate'
													value={statusDate}
													options={{ enableTime: true, dateFormat: 'M d, Y h:i K', static: true }}
													onChange={(dates) => setStatusDate(new Date(dates[0]))}
												/>
											</div>
										</>
									}
								</Modal.Body>
								<Modal.Footer className='display-flex-start'>
									<Spinner display={spinner}>
										<Button className='btn btn-dark' onClick={() => UpdateBoxStatus()}>Update</Button>
										<Button className='btn btn-light' onClick={() => { setBoxStatusHistoryData(); showBoxModal(false) }} >Cancel</Button>
									</Spinner>
								</Modal.Footer>
							</Modal >
						</Table>
					</Spinner>
				</Card>
			</Container >
		</>
	);
};

export default ShipmentList;
