import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { PAGE_TITLE } from '../helpers';

const PageHeader = ({ title, name, menus, children, className, image, pageTitle }) => {
	document.title = `${pageTitle ? pageTitle : name} ${PAGE_TITLE}`;

	return (
		<nav className='header'>
			<div className={className ? className : 'container-fluid'}>
				<div className='header-body'>
					<Row className='align-items-center'>
						{image &&
							<Col className='col-auto'>
								<div className="avatar avatar-xxl avatar-4by3">
									<img src={image} alt="..." className="avatar-img rounded img-fluid" style={{ objectFit: 'contain' }} />
								</div>
							</Col>
						}
						<Col>
							<h6 className='header-pretitle'>{title}</h6>
							<h1 className='header-title'>{name}</h1>


						</Col>
						{children && <div className='col-auto text-right'> {children}</div>}
					</Row>
					<Row>
						<Col>
							{
								menus && <>
									<ul className='nav nav-tabs nav-overflow header-tabs'>
										{
											menus.map((menu, index) => {
												return (
													<li key={index} className='nav-item'>
														<Link to={menu.url} className={`nav-link pb-3 pt-4 ${menu.active ? 'active' : ''}`}>
															{menu.name}
														</Link>
													</li>
												)
											})
										}
									</ul>
								</>
							}
						</Col>
					</Row>
				</div>
			</div>
		</nav>
	);
}

export default PageHeader;